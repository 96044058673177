import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import ConfigAPI from '@/config/api'
import Session from '@/handler/session'

Vue.use(VueAxios, axios)

const instance = axios.create();

const servicesAPI = {
  obtenerCajas() {
    var url = ConfigAPI.baseURL + "finances-cash" + Session.getToken();
    return instance.get(url);
  },
  mostrarCaja(id) {
    var url = ConfigAPI.baseURL + "finances-cash/" + id + Session.getToken();
    return instance.get(url);
  },
  agregarCaja(data) {    
    var params = data
    var url = ConfigAPI.baseURL + "finances-cash" + Session.getToken();
    return instance.post(url,params);
  },
  editarCaja(data) {    
    var params = data    
    var url = ConfigAPI.baseURL + "finances-cash/"+ data.id + Session.getToken();
    return instance.post(url,params);
  },
  eliminarCaja(id) {
    var url = ConfigAPI.baseURL + "finances-cash/"+ id + Session.getToken();
    return instance.delete(url);
  },
  abrirCaja(data) {
    var params = data    
    var url = ConfigAPI.baseURL + "finances-cash/operation/open" + Session.getToken();
    return instance.post(url,params);    
  },
  cerrarCaja(data) {
    var params = data    
    var url = ConfigAPI.baseURL + "finances-cash/operation/close" + Session.getToken();
    return instance.post(url,params);    
  },
  reAbrirCaja(data) {
    var params = data    
    var url = ConfigAPI.baseURL + "finances-cash/operation/reopen" + Session.getToken();
    return instance.post(url,params);    
  },    
  
  agregarCajaDetalle(data) {
    var params = data
    var url = ConfigAPI.baseURL + "finances-cash-detail" + Session.getToken();
    return instance.post(url,params);
  },
  eliminarCajaDetalle(id) {
    var url = ConfigAPI.baseURL + "finances-cash-detail/"+ id + Session.getToken();
    return instance.delete(url);
  },
  filtrarCajaDetalle(data, page) {
    var params = data    
    var url = ConfigAPI.baseURL + "finances-cash-detail/filter/query" + Session.getToken() + "&page=" + page;
    return instance.post(url,params);
  },

  obtenerCheques() {
    var url = ConfigAPI.baseURL + "finances-cheques" + Session.getToken();
    return instance.get(url);
  },
  mostrarCheque(id) {
    var url = ConfigAPI.baseURL + "finances-cheques/" + id + Session.getToken();
    return instance.get(url);
  },
  agregarCheque(data) {    
    var params = data
    var url = ConfigAPI.baseURL + "finances-cheques" + Session.getToken();
    return instance.post(url,params);
  },
  editarCheque(data) {    
    var params = data    
    var url = ConfigAPI.baseURL + "finances-cheques/"+ data.id + Session.getToken();
    return instance.post(url,params);
  },
  eliminarCheque(id) {
    var url = ConfigAPI.baseURL + "finances-cheques/"+ id + Session.getToken();
    return instance.delete(url);
  },
  filtrarCheques(data, page) {
    var params = data    
    var url = ConfigAPI.baseURL + "finances-cheques/filter/query" + Session.getToken() + "&page=" + page;
    return instance.post(url,params);
  },
  sumarChequesDisponibles() {
    var params = null    
    var url = ConfigAPI.baseURL + "finances-cheques/available/sum" + Session.getToken();
    return instance.post(url,params);
  },  
}

export default servicesAPI;