<template>   
  <b-card-text>
    <b-row class="mb-1">
      <b-col md="12">        
        <b-alert variant="warning" show v-if="parameters.freeAddTypeOperation">
          <h3>Parámetro General</h3>
          La actual configuración del sistema, permite aceptar movimientos <b>"DECLARADOS" y "NO DECLARADOS"</b> en una misma caja/cuenta, pasando por alto la configuración de cada una.
          <br>
          Deshabilitar el <b>Parámetro ID:<u>61</u></b>
        </b-alert>
      </b-col>

      <b-col sm="12">
        <b-button @click="add()" type="button" variant="primary" size="sm" class="btn-pill float-right ml-2" v-b-tooltip.hover title="Agregar Caja/Cuenta">
          <i class="fa fa-plus"></i> Agregar
        </b-button>
        <b-button @click="show()" type="button" variant="outline-dark" size="sm" class="btn-pill float-right ml-2" v-b-tooltip.hover title="Refrescar">
          <i class="fa fa-refresh"></i> Refrescar
        </b-button>                
      </b-col>
    </b-row>

    <b-row class="mb-3">
      <b-col md="12">
        <b-table class="mb-0 table-cash"
              responsive="sm"
              head-variant="dark"
              :hover="true"
              :small="true"
              :fixed="false"
              :items="table.items"
              :fields="table.fields"
              :filter="table.filter"
              :current-page="table.currentPage"
              :per-page="table.perPage"
              :busy="table.isBusy"     
              v-if="table.items.length">

          <template v-slot:cell(id)="data">
            <span class="font-size-15">
              <b>{{ data.item.id }}</b>
            </span>            
          </template>

          <template v-slot:cell(name)="data">
            <span class="font-size-15">
              <b>{{ data.item.name }}</b>
            </span>
          </template>

          <template v-slot:cell(nd)="data">
            <div v-if="!parameters.freeAddTypeOperation">
              <b-badge v-if="data.item.nd" variant="secondary">NO DECLARADA</b-badge>            
              <b-badge v-else variant="success">DECLARADA</b-badge>            
            </div>
            <div v-else>
              <b-badge variant="dark">AMBOS MOVIMIENTOS</b-badge>            
            </div>
          </template>          

          <template v-slot:cell(uncovered)="data">
            <b-badge v-if="data.item.uncovered" variant="success">DESCUBIERTO</b-badge>            
            <b-badge v-else variant="secondary">SIN DESCUBIERTO</b-badge>            
          </template>       

          <template v-slot:cell(only_open)="data">
            <b-badge v-if="data.item.only_open" variant="secondary">SOLO CON APERTURA</b-badge>            
            <b-badge v-else variant="success">ACEPTA SIEMPRE</b-badge>            
          </template>                 

          <template v-slot:cell(opening)="data">            
            <div v-if="data.item.only_open">
              <span v-if="data.item.opening" class="table-cash-status-open">
                <b-icon icon="unlock"></b-icon>
                ABIERTA (<span v-html="statusCash(data.item)"></span>)
              </span>            
              <span v-else class="table-cash-status-close">
                <b-icon icon="lock"></b-icon>
                CERRADA
              </span>                        
            </div>
          </template>            

          <template v-slot:cell(amount_ars)="data">                        
            <span v-if="parseFloat(data.item.total_available_ars)>=0" class="cash-text-success">
              {{Intl.NumberFormat('es-AR',{style:'currency',currency: 'ARS'}).format(data.item.total_available_ars)}}
            </span>
            <span v-else class="cash-text-danger">
              ({{Intl.NumberFormat('es-AR',{style:'currency',currency: 'ARS'}).format(data.item.total_available_ars)}})
            </span>
          </template>          

          <template v-slot:cell(f_action)="data">
            <b-dropdown right text="Acción" size="sm" variant="outline-dark" class="pull-right"> 
              <b-dropdown-item @click="reOpenCash(data.item)" v-if="data.item.only_open && !data.item.opening && data.item.status.open">
                <b-icon icon="arrow-counterclockwise"></b-icon> ReAbrir
              </b-dropdown-item>                  
              <b-dropdown-item @click="openCash(data.item)" v-if="data.item.only_open && !data.item.opening">
                <b-icon icon="unlock"></b-icon> Abrir
              </b-dropdown-item>
              <b-dropdown-item @click="closeCash(data.item)" v-if="data.item.only_open && data.item.opening">
                <b-icon icon="lock"></b-icon> Cerrar
              </b-dropdown-item>          
              
              <b-dropdown-header>Movimientos</b-dropdown-header>   
              <b-dropdown-item @click="goDetail(data.item)">
                <b-icon icon="arrows-fullscreen"></b-icon> Ver Movimientos
              </b-dropdown-item>          

              <b-dropdown-header>Acciones</b-dropdown-header>                                                                         
              <b-dropdown-item @click="edit(data.item)">
                <i class="fa fa-pencil ml-0 mr-0" style="color:blue"></i> Editar
              </b-dropdown-item>
              <b-dropdown-item @click="remove(data.item)">
                <i class="fa fa-trash mr-0 ml-0" style="color:red"></i> Eliminar
              </b-dropdown-item>
            </b-dropdown>
          </template>
        </b-table>
        <b-alert v-else variant="warning" show>No se encontraron registros</b-alert>
      </b-col>
    </b-row>
                        
    <b-row>
      <b-col>
        <b-button type="button" variant="outline-dark" size="sm" @click="$router.go(-1)">
          <i class="fa fa-angle-double-left"></i>
          Volver
        </b-button>                        
      </b-col>

      <b-col>
        <nav>
          <b-pagination class="pull-right mb-0"
                        size="sm"
                        pills=""
                        :total-rows="getRowCount(table.items)"
                        :per-page="table.perPage"
                        v-model="table.currentPage" />
        </nav>
      </b-col> 
    </b-row>            

    <!-- ########################### -->
    <!-- #####     MODALES     ##### -->
    <!-- ########################### -->

    <b-modal v-model="modal.form.active"
            header-bg-variant="dark"
            header-text-variant="white">

        <div slot="modal-header">
          {{this.modal.form.title}}
        </div>

        <b-alert variant="warning" show v-if="parameters.freeAddTypeOperation">
          <h3>Parámetro General</h3>
          La actual configuración del sistema, permite aceptar movimientos <b>"DECLARADOS" y "NO DECLARADOS"</b> en una misma caja/cuenta, pasando por alto la configuración de cada una.
          <br>
          Deshabilitar el <b>Parámetro ID:<u>61</u></b>
        </b-alert>

        <b-tabs>
          <b-tab no-body title="General">
            <b-row>
              <b-col md="12">
                <b-form-group label="Nombre">
                  <b-form-input type="text"
                                  size="sm"
                                  v-model="crud.form.name"
                                  required>
                  </b-form-input>
                </b-form-group>
              </b-col>                    
              <b-col md="12">       
                <hr>
              </b-col>
              <b-col md="12" v-if="!parameters.freeAddTypeOperation">       
                <b-form-group label="Tipo" description="Si la opción se activa, los movimientos realizados, solo pueden utilizarse desde un punto de venta no declarado.">         
                  <b-form-checkbox v-model="crud.form.nd" 
                                    switch 
                                    size="sm">
                    NO DECLARADA
                  </b-form-checkbox>                      
                </b-form-group>      
              </b-col>            
              <b-col md="12">       
                <b-form-group label="Descubierto" description="Si la opción se activa, permite a la caja/cuenta quedar en descubierto.">         
                  <b-form-checkbox v-model="crud.form.uncovered" 
                                    switch 
                                    size="sm">
                    ACEPTA DESCUBIERTO
                  </b-form-checkbox>                      
                </b-form-group>      
              </b-col>            
              <b-col md="12" class="mb-3">       
                <b-form-group label="Apertura" description="Si la opción se activa, los movimientos son aceptados solo si se hace una apertura de caja.">         
                  <b-form-checkbox v-model="crud.form.only_open" 
                                    switch 
                                    size="sm">
                    MOVIMIENTOS CON APERTURAS
                  </b-form-checkbox>                      
                </b-form-group>      
              </b-col>            

            </b-row>
          </b-tab>
          <b-tab no-body title="Cuenta Contable" v-if="isProcessAccounting">
            <b-row>
              <b-col lg="12">
                <FindObject render="search"
                        type="accountingAccountsImputation" 
                        @select-object="loadAccountingAccounts($event)" 
                        :valueID="crud.form.accounting_accounts_id"
                        :where="conditionAccountingAccountsFilters"/>
              </b-col>              
            </b-row>            
          </b-tab>       

        </b-tabs>  

        <div slot="modal-footer">
          <b-button variant="outline-secondary" class="mr-1" @click="modal.form.active=false">Cancelar</b-button>
          <b-button variant="dark" @click="save()">Guardar</b-button>          
        </div>
    </b-modal>    
  </b-card-text>                                
</template>

<script>
  import serviceAPI from './../services'
  import Error from '@/handler/error'
  import Session from '@/handler/session'  
  import Profiles from '@/config/profiles'
  import Modules from '@/config/modules'
  import Helper from '@/handler/helper' 
  import FindObject from '@/components/inc/find/findObject'
  import Param from '@/config/parameters'
  import moment from 'moment'

  export default {
    components: {
      FindObject
    },          
    data: () => {
      return {      
        table : {
          items: [],
          fields: [
            {key: 'id', label: 'ID', sortable: true, class:"align-middle", width:"5%"},
            {key: 'name', label: 'Nombre', class:"align-middle", width:"15%"},
            {key: 'nd', label: 'Tipo', class:"align-middle", width:"15%"},
            {key: 'uncovered', label: 'Descubierto', class:"align-middle", width:"15%"},
            {key: 'only_open', label: 'Apertura', class:"align-middle", width:"15%"},
            {key: 'opening', label: '', class:"align-middle text-right", width:"15%"},
            {key: 'amount_ars', label: 'Saldo', class:"align-middle text-right", width:"15%"},
            {key: 'f_action', label:'', class:"align-middle", width:"5%"},
          ],                    
          currentPage: 1,
          perPage: 50,
          isBusy: false,
        },
        crud: {
          form: {
            id: 0,
            name: '',
            nd: false,
            uncovered: false,
            only_open: false,
            accounting_accounts: null,
            accounting_accounts_id: 0,
          },
        },
        modal: {
          form: {
            active: false,
            title: ''
          },
        },
        modulesActive: [], 
        parameters: {
          freeAddTypeOperation: false
        }           
      }
    },
    computed: {
      isProcessAccounting() {
        var status = false
        this.modulesActive.forEach(element => {
          if(element.id == Modules.CONTABILIDAD) {
            status = true
          }
        })
        return status
      },
      // CONDITIONS SELECT
      conditionAccountingAccountsFilters(){
        return [
            {field: 'active', condition: true},
            {field: 'type', condition: 'Activo'}
        ];
      },      
    },
    mounted () {
      this.parameters.freeAddTypeOperation = Helper.hasParametersAccess(Param.P61)
      
      this.modulesActive = Session.getSession().auth.user.permissions.modules      
      this.show()
    },
    methods: {
      getRowCount (items) {
        return items.length
      },
      show() {        
        this.table.isBusy = true

        var result = serviceAPI.obtenerCajas()

        result.then((response) => {
          this.table.isBusy = false

          var data = response.data
          this.table.items = data
        })
        .catch(error => {
          this.table.isBusy = false
          this.$awn.alert(Error.showError(error))
        });  
      },
      add() {
        this.crud.form.id = 0                
        this.crud.form.name = ''
        this.crud.form.nd = false
        this.crud.form.uncovered = false
        this.crud.form.only_open = false        
        this.crud.form.accounting_accounts = null
        this.crud.form.accounting_accounts_id = 0

        this.modal.form.title = "Nueva Caja/Cuenta"
        this.modal.form.active = true
      },
      edit(item) {                
        this.crud.form.id = item.id
        this.crud.form.name = item.name
        this.crud.form.nd = item.nd
        this.crud.form.uncovered = item.uncovered
        this.crud.form.only_open = item.only_open
        this.crud.form.accounting_accounts = item.accounting_accounts
        this.crud.form.accounting_accounts_id = item.accounting_accounts_id

        this.modal.form.title = "Editar Caja/Cuenta"
        this.modal.form.active = true
      },
      remove(item) {
        this.crud.form.id = item.id
        this.crud.form.name = item.name

        this.$bvModal.msgBoxConfirm('¿Desea borrar el item (' + this.crud.form.id + ') - '+ this.crud.form.name + '?', {
          title: 'Borrar Concepto',
          size: 'lg',
          buttonSize: 'lg',
          okVariant: 'danger',
          okTitle: 'SI',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          headerBgVariant: 'danger',
          headerTextVariant: 'white',
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if (value) {
            let loader = this.$loading.show();
            var result = serviceAPI.eliminarCaja(this.crud.form.id);

            result.then((response) => {      
              this.modal.form.active = false        
              loader.hide()
              this.show()
              this.$awn.success("Registro eliminado");
            })
            .catch(error => {
              loader.hide()
              this.$awn.alert(Error.showError(error));
            })
          }
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error));
        })
      },
      save() {
        let loader = this.$loading.show();

        if (this.crud.form.id) {
          var result = serviceAPI.editarCaja(this.crud.form);
        } else {
          var result = serviceAPI.agregarCaja(this.crud.form);
        }

        result.then((response) => {
          this.modal.form.active = false
          loader.hide()
          this.show()
          this.$awn.success("Datos guardados con éxito");
        })
        .catch(error => {
          loader.hide()
          this.$awn.alert(Error.showError(error));
        })
      },
      loadAccountingAccounts (object) {
        if(object){
          this.crud.form.accounting_accounts = object
          this.crud.form.accounting_accounts_id = object.id             
        } else {
          this.crud.form.accounting_accounts = null
          this.crud.form.accounting_accounts_id = 0
        }
      },      

      openCash(data) {
        this.$bvModal.msgBoxConfirm('Se abrirá la caja ' + data.name + '. ¿Desea continuar?', {
          title: 'Apertura de Caja',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'dark',
          okTitle: 'ABRIR',
          cancelTitle: 'Cancelar',
          footerClass: 'p-2',
          headerBgVariant: 'dark',
          headerTextVariant: 'white',
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if (value) {          

            let loader = this.$loading.show();
            
            let result = serviceAPI.abrirCaja({
              "finances_cash_id": data.id
            });
            
            result.then((response) => {          
              loader.hide()
              this.show()
              this.$awn.success("Caja/Cuenta ABIERTA");
            })
            .catch(error => {
              loader.hide()
              this.$awn.alert(Error.showError(error));
            })
          }
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error));
        })              
      },
      closeCash(data) {
        this.$bvModal.msgBoxConfirm('Se cerrará la caja ' + data.name + '. ¿Desea continuar?', {
          title: 'Cierre de Caja',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'dark',
          okTitle: 'CERRAR',
          cancelTitle: 'Cancelar',
          footerClass: 'p-2',
          headerBgVariant: 'dark',
          headerTextVariant: 'white',
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if (value) {           
            let loader = this.$loading.show();
            
            let result = serviceAPI.cerrarCaja({
              "finances_cash_id": data.id
            });
            
            result.then((response) => {          
              loader.hide()
              this.show()
              this.$awn.success("Caja/Cuenta CERRADA");
            })
            .catch(error => {
              loader.hide()
              this.$awn.alert(Error.showError(error));
            })
          }
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error));
        })         
      },
      reOpenCash(data) {        
        this.$bvModal.msgBoxConfirm('Este procedimiento, reabrirá el último cierre realizado en la caja/cuenta ' + data.name + '. ¿Desea continuar?', {
          title: 'Reapertura de Caja',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'dark',
          okTitle: 'REABRIR',
          cancelTitle: 'Cancelar',
          footerClass: 'p-2',
          headerBgVariant: 'dark',
          headerTextVariant: 'white',
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if (value) {          

            let loader = this.$loading.show();
            
            let result = serviceAPI.reAbrirCaja({
              "finances_cash_id": data.id
            });
            
            result.then((response) => {          
              loader.hide()
              this.show()
              this.$awn.success("Caja/Cuenta REABIERTA");
            })
            .catch(error => {
              loader.hide()
              this.$awn.alert(Error.showError(error));
            })
          }
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error));
        })              
      },

      statusCash(data) {
        let status = ''
        
        if(data.status.status == 'OPEN') {
          if(data.status.open) {
            status = moment(data.status.open).locale('es').fromNow()
          } else {
            status = moment().locale('es').fromNow()
          }
          
        }

        return status       
      },

      goDetail(data) {        
        this.$router.push({ 
          name: 'FinancesStaffCashDetail', 
          params: {
            finances_cash_id: data.id,
            date_start: data.status.open ? data.status.open : null,
          } 
        })
      }
    } 
  }
</script>
<style scoped>
  .cash-text-success {
    color:green;    
    font-size: 15px;
    font-weight: bold;
  }
  .cash-text-danger {
    color:red;
    font-size: 15px;
    font-weight: bold;
  }
  .table-cash .badge {
    font-size: 15px;
  }
  .font-size-15 {
    font-size: 15px;
  }
  .table-cash-status-open {
    font-weight: bold;
    font-size: 18px;
    color:orangered;    
  }
  .table-cash-status-close {
    font-weight: bold;
    font-size: 18px;
  }
</style>